<script setup>
import { usePage } from '@inertiajs/vue3';
import { inject, onMounted, ref } from 'vue';

// Components
import ButtonLink from '@/Components/Button/Link.vue';
import Modal from '@/Components/Popup/Modal.vue';

const route = inject('route');
const props = defineProps({
    showing: Boolean,
});

const flexableSupportMail = ref('');
const flexablePhoneNumber = ref('');

const emits = defineEmits(['update:showing']);
const closeForm = () => emits('update:showing', false);

onMounted(async () => {
    flexableSupportMail.value = usePage().props.flexable_support_email;
    flexablePhoneNumber.value = usePage().props.flexable_phone_number;
});
</script>

<template>
    <Modal :open="showing" @closing="closeForm">
        <template #default>
            <h2 class="text-blue text-[32px] font-bold">{{ $t(`Shift can't be retracted`) }}</h2>
            <p class="text-base text-blue">
                {{ $t(`This shift takes place within 96 hours please contact support.`) }}
            </p>
        </template>
        <template #button-bar>
            <ButtonLink :href="'mailto:' + flexableSupportMail" class="w-auto">
                <div class="gap-4 flex-center">
                    <div class="text-base leading-none">
                        {{ $t('Mail support') }}
                    </div>
                </div>
            </ButtonLink>
            <ButtonLink :href="'tel:' + flexablePhoneNumber" class="flex flex-col w-auto text-base">
                <span> {{ $t('Call support') }}</span>
            </ButtonLink>
        </template>
    </Modal>
</template>
